<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1">
        <v-text-field
          v-model="search"
          dense
          solo
          :label="$t('search overtimes')"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="white"
              @click="getOvertimes()"
              ><v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("refresh table") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="overtimeDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="overtimeDate"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              in
              solo
            ></v-text-field>
          </template>
          <v-date-picker v-model="overtimeDate" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.dialog.save(overtimeDate);
                dateFilter();
              "
            >
              {{ $t("ok") }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-flex>
    </v-layout>
    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <div v-else>
      <v-layout row wrap justify-center v-if="employees">
        <v-flex xs12 sm6 md6 lg6 class="text-left">
          <v-avatar size="40">
            <v-img :src="employees.avatar" style="cursor: pointer">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    width="5"
                    indeterminate
                    :color="$store.state.primaryColor"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
          <span class="text-h5 font-weight-bold">
            {{
              employees.names.first +
              " " +
              employees.names.middle +
              " " +
              employees.names.last
            }}</span
          >
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm4 md4 lg4 class="py-2 text-right">
          <v-btn
            v-if="userAccess == 1"
            :color="$store.state.primaryColor"
            outlined
            small
            @click="newOvertimeEntry(employeeId)"
          >
            {{ $t("new overtime") }}
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 md12 lg12>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="filteredOvertimes"
            :search="search"
            :sort-by.sync="sortByDate"
            :sort-desc.sync="sortDesc"
            :items-per-page="20"
          >
            <template v-slot:[`item.businessId`]="{ item }">
              {{ getBusinessName(item.businessId) }}
            </template>
            <template v-slot:[`item.overtimeDate`]="{ item }">
              {{ formatDate(item.overtimeDate) }}
            </template>
            <template v-slot:[`item.overtimeHours`]="{ item }">
              <v-btn small text dark left :color="$store.state.primaryColor">
                {{ item.overtimeHours }}</v-btn
              >
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";
import db from "@/plugins/fb";
import { format } from "date-fns";
import PleaseWait from "@/components/templates/PleaseWait";
export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    loading: true,
    search: "",
    pleaseWait: true,
    modal: false,
    sortDesc: true,
    sortByDate: "overtimeDate",
    filteredOvertimes: [],
    overtimes: [],
    employees: [],
    overtimeDate: format(new Date(), "yyyy-MM-dd"),
  }),

  created() {
    this.getOvertimes();
    this.getEmployeeDetails();
    this.checkAccessLevel();
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("business"),
          value: "businessId",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("overtime date"),
          value: "overtimeDate",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("hours"),
          value: "overtimeHours",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
          filterable: false,
        },
      ];
    },
  },

  methods: {
    fullName(obj) {
      for (let x in obj) {
        return obj[x] + " ";
      }
    },

    viewAttendance(empID) {
      this.$router.push({
        name: "ViewAttendanceDetails",
        params: { id: empID },
      });
    },

    checkAccessLevel() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
      this.userAccess = data.accessLevel.isSuperAdmin
        ? 0
        : data.accessLevel.isOwner
        ? 1
        : data.accessLevel.isEmployee
        ? 2
        : 3;
    },

    getEmployeeDetails() {
      db.collection("employees")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.employees = doc.data();

          this.employeeId = this.employees.employeeId;
          this.employees.names.first;
          this.employees.names.middle;
          this.employees.names.last;
          this.employees.avatar;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getOvertimes() {
      this.overtimes = [];

      db.collection("overtimes")
        .where("employeeId", "==", this.$route.params.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.overtimes.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.pleaseWait = false;
          this.filteredOvertimes = this.overtimes;

          this.loading = false;
        });
    },

    getBusinessName(id) {
      let busId = this.$store.state.businesses.find((item) => item.id == id);
      let busName = busId.businessName;
      return busName;
    },
    dateFilter() {
      let filtered = this.overtimes.filter(
        (item) =>
          format(item.overtimeDate.toDate(), "yyyy-MM-dd") == this.overtimeDate
      );
      this.filteredOvertimes = filtered;
    },
    newOvertimeEntry(id) {
      this.$router.push({ name: "CreateOvertime", params: { id: id } });
    },

    formatDate(val) {
      return format(val.toDate(), "dd MMMM, yyyy HH:mm:a");
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
